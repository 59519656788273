import {Page} from "../components/page";

export const RiskAppetitePolicy = () => {
  return (
      <Page>
          <div className="policy-container">
              <h1>Risk Appetite Policy</h1>
              <p>
              Extract from KURTLEY QURESHI CONSULTANTS Risk Appetite Policy detailing the
              Prohibited and Restricted Business activities.
              </p>
              <p>
              Risk appetite is the amount of risk KURTLEY QURESHI CONSULTANTS is willing to
              take on. Among other things, a significant part of the Risk Appetite is related to non-
              cooperation and service to businesses that may be illegal, work in absence of
              required licenses or permissions or cause reputational damage.
              </p>
              <p>
              In general, KURTLEY QURESHI CONSULTANTS does not provide its services to legal
              entities and individuals who have been sanctioned personally or are connected to
              black list jurisdictions. KURTLEY QURESHI CONSULTANTS follows strict policies
              regarding trading with PEP’s, sanctioned counterparties, criminals and persons
              involved in terrorism.
              </p>
              <p>
              KURTLEY QURESHI CONSULTANTS does not provide and/or accept transactions with:
              </p>
              <p>
                <ul>
                  <li>
                      Individuals, legal entities and bodies which are residents in the non-cooperative
                  jurisdictions included in the Financial Action Task Force (FATF) list of states;
                  </li>
                  <li>
                      A country or jurisdiction and individuals or entities that are subjects to sanctions,
                      embargos or similar measures issued by the European Union, the United Nations,
                      the Office of Foreign Assets Control. KURTLEY QURESHI CONSULTANTS does not
                      provide and/or accept transactions with industries linked to the following business
                      activities:
                  </li>
                  <li>
                      Firms involved in the servicing of illegal goods/services including but not limited
                    to: Counterfeit Goods/trademark infringement, Child Labor, Prostitution;
                  </li>
                  <li>
                      Unregulated financial services (where licensing required);
                  </li>
                  <li>
                      CFD (Contract For Difference);
                  </li>
                  <li>
                      Un-licensed FX broker;
                  </li>
                  <li>
                      Binary options;
                  </li>
                  <li>
                      Un-licensed gambling or lottery;
                  </li>
                  <li>
                      Adult Entertainment and products;
                  </li>
                  <li>
                      Synthetic stimulants;
                  </li>
                  <li>
                      Illegal Drugs, Illegal Sale of Prescription Drugs;
                  </li>
                  <li>
                      Unregistered and unlicensed Charities;
                  </li>
                  <li>
                      Companies formed of Bearer Shares. KURTLEY QURESHI CONSULTANTS reserves
                      the right to change this list depending on the requirements of the Law, Regulatory
                      framework, as well as internal decisions of Compliance and Risk management.
                  </li>
              </ul>
              </p>
          </div>
      </Page>
  )
}