import {Page} from "../components/page";
import {Preview} from "../sections/about-us-sections/preview";
import {Benefits} from "../sections/about-us-sections/beneits";
import {Goals} from "../sections/about-us-sections/goals";

export const AboutUs = () => {
    return (
        <Page>
            <Preview/>
            <Benefits/>
            <Goals/>
        </Page>
    )
}