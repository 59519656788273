import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {ErrorMessage, Field, Form, Formik} from "formik";
import { object, string, boolean } from 'yup';
import {Page} from "../components/page";
import letter from "../assets/letter.svg"
import phone from "../assets/phone.svg"
import location from "../assets/location.svg"

export const ContactUs = () => {
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const validationSchema = object({
        name: string().required('Name field is required'),
        email: string().email().required('Email field is required'),
        message: string().required('Message field is required'),
        terms: boolean().test('terms', 'Agreement should be accepted', (value) => value)
    });

    const onSubmit = useCallback(
        async (values, { setSubmitting }) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const verifyToken = await executeRecaptcha();

            setSubmitting(false)
            fetch('https://api.finstee.com/contact-us', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    message: values.message,
                    captcha: verifyToken,
                })
            })
                .then(() => navigate('/'))
                .catch(() => {})
        },
        [executeRecaptcha, navigate]
    );

    return (
        <Page>
            <section className="contact-us container">
                <div className="contact-information">
                    <h1>Contact us</h1>
                    <h2>Get a free consultation!</h2>
                    <p>Ready to start the registration process?</p>
                    <p className="marg">Fill in the application form.</p>
                    <p>Do you have questions left?</p>
                    <p>Contact us.</p>
                    <a href="mailto:office@finstee.com">
                        <img src={letter} alt="letter"/>
                        <span>office@finstee.com</span>
                    </a>
                    <a href="tel:+17786505504">
                        <img src={phone} alt="phone"/>
                        <span>+1 (778) 650-5504</span>
                    </a>
                    <a href="https://www.google.com/maps/place/701+W+Georgia+St+%231500,+Vancouver,+BC+V7Y+1C6,+Canada" target="_blank" rel="noreferrer">
                        <img src={location} alt="location"/>
                        <span>Office - 701 WEST GEORGIA STREET, SUITE 1500 VANCOUVER, BC, CANADA V7Y1C6</span>
                    </a>
                </div>
                <div className="contact-form">
                    <Formik
                        initialValues={{ email: '', name: '', message: '', terms: false }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="form-row">
                                    <label htmlFor="name">Name</label>
                                    <Field type="text" name="name"/>
                                    <ErrorMessage name="name" component="span"/>
                                </div>
                                <div className="form-row">
                                    <label htmlFor="email">Email</label>
                                    <Field type="email" name="email"/>
                                    <ErrorMessage name="email" component="span"/>
                                </div>
                                <div className="form-row">
                                    <label htmlFor="message">Message</label>
                                    <Field as="textarea" name="message" placeholder='Type your message...'/>
                                    <ErrorMessage name="message" component="span"/>
                                </div>
                                <div className="form-row">
                                    <label>
                                        <Field type="checkbox" name="terms" />
                                        I accept the Terms
                                    </label>
                                    <ErrorMessage name="terms" component="span"/>
                                </div>
                                <button type="submit" disabled={isSubmitting} className='white-stroke l'>
                                    Submit
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <p>
                        <span>This site is protected by reCAPTCHA and the Google </span>
                        <a href='https://policies.google.com/privacy' target='_blank' rel="noreferrer">Privacy Policy</a>
                        <span> and </span>
                        <a href='https://policies.google.com/terms' target='_blank' rel="noreferrer">Terms ofService</a>
                        <span> apply.</span>
                    </p>
                </div>
            </section>
        </Page>
    )
}
