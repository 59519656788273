import {useNavigate} from "react-router-dom";
import {Page} from "../components/page";
import {handleGoToRegistration} from "../utils/navigation-utils";
import transferring from "../assets/money_transfering.png";
import accounts from "../assets/accounts.png";
import processing from "../assets/payment_processing.png";

export const OurServices = () => {
    return (
        <Page>
            <section className="our-services container">
              <h1>Our Services</h1>
                <div className="our-service">
                    <div className="our-service-image-preview">
                        <img src={transferring} alt="transferring"/>
                    </div>
                    <div className="our-service-content">
                        <h2>Money transferring and currency exchange</h2>
                        <p>Make international payments in various currencies quickly and securely. We support SWIFT and SEPA payments. With Finstee, you can also exchange currencies online at the best rates.</p>
                        <button className="white-stroke" onClick={handleGoToRegistration}>Get started now</button>
                    </div>
                </div>
                <div className="our-service">
                    <div className="our-service-content">
                        <h2>Individual and business accounts</h2>
                        <p>Each Finstee client receives an individual IBAN. It guarantees fast and secure domestic and international payments, allows bulk payments, online currency conversion, and more.</p>
                        <button className="white-stroke" onClick={handleGoToRegistration}>Get started now</button>
                    </div>
                    <div className="our-service-image-preview">
                        <img src={accounts} alt="accounts"/>
                    </div>
                </div>
                <div className="our-service">
                    <div className="our-service-image-preview">
                        <img src={processing} alt="processing"/>
                    </div>
                    <div className="our-service-content">
                        <h2>Payment Processing</h2>
                        <p>Finstee payment processing solution can be used on your website, in a mobile app, online store or another platform. You can receive online payments from customers fast and efficiently.</p>
                        <button className="white-stroke" onClick={handleGoToRegistration}>Get started now</button>
                    </div>
                </div>
            </section>
        </Page>
    )
}