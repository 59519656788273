import {Page} from "../components/page";

export const CookiesPolicy = () => {
  return (
      <Page>
          <div className="policy-container">
              <h1>Cookie Policy</h1>
              <h2>Confidentiality Statement</h2>
              <p>
                  The information in this document is confidential to the person to whom it is addressed and
                  should not be disclosed to any other person. It may not be reproduced in whole, or in part, nor
                  may any of the information contained therein be disclosed without the prior consent of the
                  directors of KURTLEY QURESHI CONSULTANTS LTD (“the Company”).
              </p>
              <p>
                  A recipient may not solicit, directly or indirectly (whether through an agent or otherwise) the
                  participation of another institution or person without the prior approval of the directors of the
                  Company.<br/>
                  The contents of this document have not been independently verified and they do not purport to
                  be comprehensive, or to contain all the information that a prospective investor may need.
                  No representation, warranty or undertaking, expressed or implied is or will be made or given
                  and no responsibility or liability is or will be accepted by the Company or by any of its
                  directors, employees or advisors in relation to the accuracy or completeness of this document or
                  any other written or oral information made available in connection with the Company.
                  Any form of reproduction, dissemination, copying, disclosure, modification, distribution and or
                  publication of this material is strictly prohibited.
              </p>

              <h2>Cookie Policy</h2>
              <p>
                  <h3>Introduction</h3>
                  KURTLEY QURESHI CONSULTANTS Limited (or “we”) respects and cares about Your
                  privacy. This Policy describes the procedures and practices regarding our use of cookies on the
                  website  www._______________.com.   We   are   using   cookies   to   improve   the   website’s
                  functionality and provide safer user experience.
              </p>
              <p>“You” in this Policy refers to a visitor/user of our website.</p>
              <p>Please be informed that we may from time to time update this Policy.</p>

              <p>
                  <h3>What are cookies?</h3>
                  Most websites need to collect certain basic information about their users to work properly.
                  Therefore, websites rely on cookies – small text files, that are stored on Your device for
                  browsing (computer, mobile device or tablet). They contain basic information about Your
                  internet use and Your browser sends these cookies back to our website every time You revisit it,
                  so it can recognize Your computer or mobile device. Cookies used by our website cannot harm
                  Your device and we don't ever store Your sensitive data in cookies.
              </p>

              <p>
                  <h3>How we use cookies?</h3>
                  Cookies serve many functions but in general we process cookies on our website for the
                  following purposes:
                  <ol>
                      <li>
                          To remember Your login state so You don’t have to login as You navigate through our
                        website and Your dashboard;
                      </li>
                      <li>
                          To remember the parts of site visited by the user to provide engaging content for the site
                        visitors and further disclose issues that are topical for the users;
                      </li>
                      <li>
                          To collect information about computers and web browsers used to access our website (this
                        information helps us monitor for and detect potentially harmful or illegal use of our services);
                      </li>
                      <li>
                          To   provide   the   smooth   browsing   experience   for   visitors   by   verifying   and   ensuring
                        compatibility of the site with the device and browser version;
                      </li>
                      <li>
                          To maintain the statistics on how often users visit certain parts of the site, the data is
                        implemented by developers of the site to tailor its usability and enhance the performance.
                      </li>
                  </ol>
              </p>
              <p>
                  <h3>What types of cookies do we use?</h3>
                  <ol>
                      <li>
                          Essential cookies. These are essential to enable You to navigate around our website and use
                          its features in full. Without these we would be incapable to enable the use of our Website’s
                          features. We also rely on the essential cookies to ensure the highest standards of data security
                          on our pages and to comply with industry security standards;
                      </li>
                      <li>
                          Performance cookies. We rely on performance (or analytics) cookies to help us understand
                          how You arrived at our website, the way and how long You use it and ways we can improve
                          Your user experience. These cookies are anonymous and do not process any personal data.
                          Some of the performance cookies we use are issued as part of services provided by selected
                          third parties;
                      </li>
                      <li>
                          Functionality cookies. These cookies allow us to remember choices You make on our
                          website and to provide You enhanced and more personal features;
                      </li>
                      <li>
                          Targeting cookies. Our website does not use targeting cookies to deliver targeted advertising
                          to You.
                      </li>
                  </ol>
              </p>

              <p>
                  <h3>How to limit the use of cookies?</h3>
                  You can limit the use of cookies at any time by changing Your browser settings and deleting the
                  cookies stored. If You don’t want cookies to be used on Your devices, simply change the
                  security settings of Your browser. Security settings may differ in browsers and have to changes
                  in each of them. By not to accepting the cookies, Your ability to use of our website’s features
                  may be affected and some of them may not operate as intended.
              </p>

              <p>
                  <h3>Contact us</h3>
                  In case You have any questions or comments regarding our Cookie Policy, please contact us at
                  <a href='mailto:office@finstee.com' target='_blank' rel="noreferrer">office@finstee.com</a>
                  In case You have any questions or comments regarding our Cookie Policy, please contact us at
                  <a href='mailto:office@finstee.com' target='_blank' rel="noreferrer">office@finstee.com</a>
              </p>
          </div>
      </Page>
  )
}